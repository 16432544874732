@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-underline-hover {
  text-decoration: none;
}

.text-underline-hover:hover {
  text-decoration: underline;
}

a:not([class]) {
  text-decoration: none;
}

a:not([class]):hover {
  text-decoration: underline;
}

.product-extras img {
  max-width: 100%;
  height: auto;
}

.dot-animation {
  display: inline-block;
  margin-left: 4px; /* Adjust the spacing between dots as needed */
}

.dot1 { animation: dot-blink 2s infinite 0s; }
.dot2 { animation: dot-blink 2s infinite 0.5s; }
.dot3 { animation: dot-blink 2s infinite 1s; }

@keyframes dot-blink {
  0%, 20%, 40%, 60%, 80%, 100% {
    opacity: 0;
  }
  10%, 30%, 50%, 70%, 90% {
    opacity: 1;
  }
}