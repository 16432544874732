.announcement-banner {
  padding: 0.75rem 0;
  margin-bottom: 1.5rem;
}

.announcement-banner .alert {
  border: none;
  border-radius: 8px;
  background: linear-gradient(145deg, #ffffff, #f0f7ff);
  color: #2c3e50;
  padding: 1rem 1.5rem;
  box-shadow: 
    0 2px 4px rgba(50, 50, 93, 0.08), 
    0 1px 2px rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(5px);
}

.announcement-content {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.announcement-content p {
  line-height: 1.5;
  margin-bottom: 0;
  font-size: 0.95rem;
  color: #4a5568;
}

.announcement-content .highlight {
  color: #2d3748;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.95), rgba(255, 236, 236, 0.95));
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 500;
  border: 1px solid rgba(221, 107, 32, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
}

.announcement-content strong {
  color: #e53e3e;
  font-weight: 700;
  background: linear-gradient(120deg, rgba(255, 241, 241, 0) 0%, rgba(255, 241, 241, 0.8) 100%);
  padding: 0.2em 0.4em;
  border-radius: 4px;
}

.announcement-content .signature {
  font-style: italic;
  color: #718096;
  font-size: 0.9rem;
} 